<template>
	<div class="unit-input">
		<label :for="id">{{ label }}</label>
		<div class="controls">
			<input
				ref="txt"
				:id="id"
				type="number"
				:name="label"
				:value="value.value"
				@input="input"
				placeholder="..."
				autocomplete="false"
			/>
			<div class="select">
				<a @click.prevent="openSelect($event)" href="#">{{
					unitData[unitType][value.key].name
				}}</a>
				<div v-if="isSelectOpen" :class="['select-menu', 'select-menu-' + id]">
					<div
						class="select-item"
						v-for="(key, i) in unitKeys"
						:key="i"
					>
						<a @click.prevent="() => selectFactor(key)" href="#">{{
							unitData[unitType][key].name
						}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	props: {
		id: {
			type: String
		},
		label: {},
		unitType: {
			type: String,
			default: "time"
		},
		value: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		isSelectOpen: false,
		selectContainer: null,
	}),
	computed: {
		...mapGetters({
			unitData: "unitData",
		}),
		unitKeys() {
			return Object.keys(this.unitData[this.unitType]);
		},
	},
	methods: {
		input() {
			let value = this.$refs.txt.value;
			value = parseInt(value);
			if (isNaN(value)) value = 0;

			this.$emit("input", { ...this.value, value });
		},
		clickSelect(e) {
			if (e.defaultPrevented) return;

			if (!e.target.matches(".select-menu-" + this.id)) {
				this.closeSelect();
			}
		},
		closeSelect() {
			this.isSelectOpen = false;
			if (this.selectContainer) {
				this.selectContainer.removeEventListener(
					"click",
					this.clickSelect
				);
			}
		},
		openSelect(e) {
			this.isSelectOpen = true;
			const dialog = e.target.closest(".dialog");

			if (dialog) {
				e.preventDefault();
				dialog.addEventListener("click", this.clickSelect);
				this.selectContainer = dialog;
			}
		},
		selectFactor(key) {
			this.$emit("input", { ...this.value, key });
			this.closeSelect();
		},
	},
};
</script>
