<template>
	<div class="select">
		<a href="#" @click.prevent>{{ getSelectedText() }}</a>
		<div class="select-menu" v-if="options && options.length">
			<div v-for="(option, i) in options || []" :key="i">
				<a
					href="#"
					@click.prevent="() => input(fnOptionValue(option))"
					v-html="fnOptionText(option || {})"
				></a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {},
		label: {},
		value: {
			default: null,
		},
		options: {
			default: () => [],
		},
		fnOptionText: {
            type: Function,
			default: (o) => o.text,
		},
		fnOptionValue: {
            type: Function,
			default: (o) => o.value,
		},
	},
	data: () => ({
		optionIndex: {},
		selected: null,
	}),
	watch: {
		options: (next) => {
			this.optionIndex = next.reduce((p, c) => {
				const value = this.fnOptionValue(c);
				p[value] = c;
				return p;
			}, {});
		},
		value: (next) => {
			this.selected = this.optionIndex[next];
		},
	},
	methods: {
		input(value) {
			this.$emit("input", value);
		},
		getSelectedText() {
			if (this.selected) {
				return this.fnOptionText(this.selected);
			}

			return this.label;
		},
	},
};
</script>
