<template>
	<div class="page">
		<h2>Connected Apps</h2>

		<div v-if="user.loading">
			Loading...
		</div>

		<section>
			<h3>Trello</h3>

			<div v-if="!user.current.connectedToTrello">
				<h4>
					<a
						target="_blank"
						:href="
							`https://trello.com/1/authorize?name=NoNought&scope=read,write&response_type=token&key=${apiKey}`
						"
						>Connect <i class="fal fa-fw fa-external-link-alt"></i
					></a>
				</h4>
				<TextInput label="Token" v-model="token" />
				<button @click="trelloConnect">Save</button>
			</div>
			<div v-else>
				<h4>Connected!</h4>
				<button @click="trelloDisconnect">Disconnect</button>
			</div>
		</section>

		<div class="action-bar-container">
			<div class="action-bar-row">
				<router-link class="btn btn-action" to="/user">
					<i class="fal fa-fw fa-arrow-left"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TextInput } from "../components/inputs/index";

export default {
	components: { TextInput },
	data: () => ({
		apiKey: "a725306fb1447b530d3b04f984a1650c",
		token: "",
	}),
	computed: {
		...mapGetters({
			user: "user/data",
		}),
	},
	methods: {
		...mapActions({
			updateTrelloKey: "user/updateTrelloKey",
		}),
		trelloConnect() {
			this.updateTrelloKey(this.token);
		},
		trelloDisconnect() {
			this.updateTrelloKey(null);
		},
	},
};
</script>
